import React from 'react';
import Page from '../../components/pages/Page';

const i18n = {
  titleId: 'contact',
  content: (
    <>
      <p>Ik ontmoet graag nieuwe mensen om <strong>te praten</strong> en <strong>van elkaar te leren</strong>. Verder help ik je graag <strong>problemen op te lossen</strong> en kijk uit naar <strong>uitdagingen</strong> en <strong>opportuniteiten</strong> die mijzelf en anderen kunnen helpen <strong>groeien</strong>.&nbsp;</p>
      <p>Laten we kennis maken!</p>
      <p>Email:&nbsp;<a href="mailto:lucas.vanremoortere@gmail.com">lucas.vanremoortere@gmail.com</a><br />Linkedin:&nbsp;<a href="https://be.linkedin.com/in/lucas-van-remoortere-24055844" target="_blank" rel="noopener noreferrer">https://www.linkedin.com/in/lucas-van-remoortere-24055844/</a><br />GitHub:&nbsp;<a href="https://github.com/derozan10" target="_blank" rel="noopener noreferrer">https://github.com/derozan10</a></p>
    </>
  ),
  description: `
    Laten we kennis maken. Ik kijk er naar uit nieuwe mensen te leren kennen, elkaar te helpen, kennis te delen en van elkaar te leren.
  `
};

export default (props) => <Page i18n={i18n} {...props} />;
