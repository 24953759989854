const authors = {
  lucas: {
    name: 'Lucas Van Remoortere',
    additionalName: 'Lucas',
    address: 'Antwerp BE',
    birthDate: '1989-09-05',
    birthPlace: 'Deurne, Antwerp',
    brand: 'Lucas Van Remoortere, Web Developer, ReactJs developer',
    children: '...',
    email: 'lucas.vanremoortere@gmail.com',
    familyName: 'Van Remoortere',
    gender: 'Male',
    givenName: 'Lucas',
    height: '...',
    homeLocation: 'Antwerp',
    jobTitle: 'Web Developer',
    knows: '...',
    memberOf: '...',
    nationality: 'Belgian',
    owns: '...',
    parent: '...',
    performerIn: '...',
    publishingPrinciples: '...',
    relatedTo: '...',
    seeks: '...',
    sibling: '...',
    telephone: '...',
    weight: '...',
    workLocation: '...',
    worksFor: '...',
    description: '...',
    disambiguatingDescription: '...',
    identifier: '...',
    image: 'http://www.gravatar.com/avatar/57a49c43e127f881d2d29bbfa51bd3ff',
    sameAs: 'https://lucasvanremoortere.com/en/about/',
    url: 'https://lucasvanremoortere.com/en/about/'
  }
};

const getAuthor = (id) => {
  const author = {
    ...authors[id],
    '@type': 'Person'
  };
  return author
    ? author
    : authors[0];
};

module.exports = {
  authors,
  getAuthor
};
